const profiles = [
  {
    src: require("@/assets/prf000-optim.webp"),
    name: "Sophie Ducamp-Monod",
    title: "Notaire associé",
    bio: "Si les activités traditionnelles du notariat constituent le socle de l’activité quotidienne de Sophie DUCAMP-MONOD, c’est de pouvoir les déployer dans un contexte international qui attise son intérêt.",
    eBio: "If the traditional notarial matters constitute the base of Sophie DUCAMP-MONOD's daily activity, she has been able to deploy them in an international context.",
    sBio: "Son las actividades notariales tradicionales las que constituyen la base de la actividad diaria de Sophie Ducamp-Monod, su interés es espoleado especialmente cuando puede desplegarlas en un contexto internacional.",
  },
  {
    src: require("@/assets/prf001.webp"),
    name: "Jean-Sebastien Rouxel",
    title: "Notaire associé",
    bio: "Jean-Sébastien ROUXEL est responsable du service immobilier de l’Étude, s’occupant tant des acquisitions que de la mise en place des financements et des structures d’acquisition.",
    eBio: "Jean-Sébastien ROUXEL specializes in real estate and banking law. He supports individual, professional and institutional clients, in sales and financing matters, from the simplest to the most complex.",
    sBio: "Jean-Sébastien ROUXEL es el responsable del servicio inmobiliario de la notaría, se ocupa tanto de las compras como de la financiación y de las estructuras de adquisición.",
  },
  {
    src: require("@/assets/prf002-optim.webp"),
    name: "Sophie Thibert-Belaman",
    title: "Notaire associé",
    bio: "Manifestant un vif intérêt pour l’interprofessionnalité, Sophie THIBERT-BELAMAN est de surcroît diplômée avocate et collabore avec un grand nombre de cabinets d’avocats de renom sur la place.",
    eBio: "Sophie THIBERT-BELAMAN has a keen interest in interprofessionality and is a qualified lawyer. She works with a large number of renowned law firms in the region.",
    sBio: "Sophie THIBERT-BELAMAN, interesada en el trabajo interprofesional y abogada titulada, colabora con numerosos bufetes de renombre de la región.",
  },
  {
    src: require("@/assets/prf003-optim.webp"),
    name: "Thomas Semere",
    title: "Notaire associé",
    bio: "Ayant vécu à l’étranger et débuté sa carrière dans une étude avec une clientèle internationale importante, Thomas SEMERE est à même de traiter tous types de dossiers en langue anglaise ainsi qu’avec des interlocuteurs et conseils juridiques étrangers.",
    eBio: "Thomas SEMERE's professional activities began abroad in a firm with a large international clientele. Throughout his career overseas, Thomas SEMERE dealt with a broad range of cases, professionals and clients. He is qualified to handle all types of cases in English. He can interact and collaborate with foreign legal advisers and international contacts if a caseso requires.",
    sBio: "Su actividad profesional se inició en el extranjero en un bufete con una amplia clientela internacional. A lo largo de su carrera en el extranjero, Thomas SEMERE se ocupó de una amplia gama de casos, profesionales y clientes. Está capacitado para tratar todo tipo de casos en inglés. Puede interactuar y colaborar con asesores jurídicos extranjeros y contactos internacionales si el caso lo requiere.",
  },
  {
    src: require("@/assets/prf004.webp"),
    name: "Helene Peisse",
    title: "Notaire associé",
    bio: "Au-delà de la pratique du droit notarial classique à laquelle elle est très attachée, Hélène PEISSE est spécialisée dans les dossiers ayant une dimension internationale et notamment les transmissions de patrimoine (donation, succession, testament), les divorces et les acquisitions en France par les non-résidents.",
    eBio: "Hélène PEISSE's experience in British and American Law Firms has enabled her to acquire a great working command of the English language, an openness towards international clients and professional relations valuable for dealing with complex cross-border cases.",
    sBio: "Su experiencia en bufetes de abogados anglosajones le ha permitido adquirir un gran dominio del trabajo en lengua inglesa, una apertura hacia una clientela internacional y relaciones profesionales valiosas para la tramitación de las operaciones transfronterizas complejas.",
  },
  {
    src: require("@/assets/prf005-optim.webp"),
    name: "Sylvain Champeix",
    title: "Notaire",
    bio: "Sylvain CHAMPEIX intervient essentiellement dans tous types d’opérations immobilières et commerciales, simples ou complexes, pour le compte de clients particuliers ou professionnels, et plus spécialement dans le domaine de la cession d’actifs immobiliers d’entreprise. ",
    eBio: "Sylvain CHAMPEIX’s professional expertise lies principally in all types of real estate and commercial transactions, being particularly competent in sales of commercial real estate assets. He offers guidance and support to both private and professional clients, either on simple or complex cases.",
    sBio: "Interviene principalmente en todo tipo de transacciones inmobiliarias y comerciales, simples o complejas, por cuenta de clientes privados o profesionales, y más concretamente en el ámbito de la transmisión de activos inmobiliarios de empresas.",
  },
  {
    src: require("@/assets/prf006-optim.webp"),
    name: "Heloise Do",
    title: "Notaire",
    bio: "Heloise DO accompagne les particuliers dans leurs projets immobiliers mais également les professionnels, ayant développé une expertise particulière en droit commercial et notamment un grand intérêt pour les cessions de fonds de commerce et de baux commerciaux.",
    eBio: "Heloise DO assists and advises individuals and professionals in their real estate projects, having developed expertise in commercial law. She is highly qualified in matters of business transfers and commercial leases.",
    sBio: "Asiste a los particulares en sus proyectos inmobiliarios pero también a los profesionales. Ha desarrollado una experiencia particular en derecho mercantil y un gran interés en las transmisiones de empresas y los arrendamientos de locales comerciales.",
  },
  {
    src: require("@/assets/prf007.webp"),
    name: "Sandrine\n Deswel-Fernandes",
    title: "Notaire",
    bio: "Sandrine DESWEL-FERNANDES s’est, dans un premier temps, formée au droit immobilier, puis au droit de la famille, pour ensuite se spécialiser essentiellement en droit de la famille et principalement pour le traitement des dossiers de successions.",
    eBio: "Sandrine DESWEL-FERNANDES first trained in real estate law before specializing in family law, processing inheritance files. Due to her expertise concerning inheritance matters, she is able to provide the best support and advice to families whom she represents.",
    sBio: "Primero se formó en derecho inmobiliario, luego en derecho de familia, y más tarde se especializó principalmente en derecho de familia y sobre todo en herencias.",
  },
  {
    src: require("@/assets/prf008.webp"),
    name: "Gladys Hachon",
    title: "Notaire",
    bio: "Grâce à ces compétences en langue anglaise, Gladys HACHON est par en mesure d’assurer le suivi des dossiers de clients étrangers et travaille ainsi régulièrement avec les associés en charge du Droit international, sur leurs dossiers immobiliers.",
    eBio: "Fluent in English, Gladys HACHON accompanies foreign clients in their projects. She also regularly works on real estate cases with our partners in charge of international law matters",
    sBio: "Por otra parte, esta también capaz poner sus facultades al servicio de la clientela extranjera, gracia a su capacidad exprimirse en inglés.",
  },
  {
    src: require("@/assets/prf009.webp"),
    name: "Audrey Alcabas",
    title: "Notaire",
    bio: "Audrey ALCABAS est spécialisée en droit de la famille et en gestion patrimoniale. Elle accompagne les familles dans les différentes étapes de leur vie, notamment dans le traitement des dossiers de successions, transmissions anticipées du patrimoine, régime matrimoniaux, pactes civils de solidarité.",
    eBio: "Audrey ALCABAS is particularly attentive to the needs of families at different stages of their lives and responds to their legal and tax needs with benevolence and conscientiousness. She handles cases as diverse as estate planning, antenuptial agreements, civil partnerships, or transfers by inheritance.",
    sBio: "Es especializada en derecho de la familia y gestión patrimonial. Acompaña las familias en las distintas etapas de su vida, para todos los asuntos que tratan de sucesiones, transmisiones anticipadas, cambio de regímenes matrimoniales o uniones registradas.",
  },
  {
    src: require("@/assets/prf010.webp"),
    name: "Brigitte Giudice-Nizard",
    title: "Notaire",
    bio: "Diplômée des facultés de NICE et d’AIX-MARSEILLE, titulaire du Diplôme Supérieur de Notariat, Brigitte Nizard met désormais tout particulièrement son expérience et ses connaissances au service des particuliers qu’elle accompagne dans leurs projets personnels et professionnels.",
    eBio: "Brigitte NIZARD holds a degree from the faculties of Nice and Aix-Marseille, as well as a Master’s Degree in Notarial Law (Diplôme Supérieur de Notariat).",
    sBio: "Diplomada de las facultades de NIZA y AIX-MARSELLA, titular del Diploma Superior del Notariado, invierte ahora principalmente su experiencia y sus conocimientos al servicio de la clientela particular; Le acompaña en sus proyectos personales o profesionales.",
  },
  {
    src: require("@/assets/prf011.webp"),
    name: "Jean-Remi Cabane",
    title: "Notaire",
    bio: "Jean-Remi CABANE est spécialisé en immobilier des particuliers et accompagne ses clients dans leurs projets personnels et professionnels.",
    eBio: "Jean-Rémi CABANE specialises in real estate for individuals and professionals. He assists and offers personal guidance to his clients for their projects.",
    sBio: "Se especializa en el derecho inmobiliario de los particulares, y acompaña sus clientes en sus proyectos tan personales como profesionales.",
  },
  {
    src: require("@/assets/prf012-optim.webp"),
    name: "Magali Delaunay-Brunel",
    title: "Notaire",
    bio: "Titulaire d’un Diplôme Supérieur du Notariat, Magali DELAUNAY-BRUNEL est une jeune notaire en charge de divorces, successions, donations, toujours avec rigueur et humanité.",
    eBio: "Magali DELAUNAY-BRUNEL holds a Master’s degree in Notarial Law. She assists her clients in divorces, inheritances or gifts, and always with rigour and humanity.",
    sBio: "Titular del Diploma Superior del notariado, Magali DELAUNAY-BRUNEL arregla los divorcios, las sucesiones, prepara donaciones, siempre con rigor y humanidad.",
  },
];

// BIOGRAPHIES ------------------------------------------------------------------------------------------------------------------------------------------------------------

const biographies = [
  {
    bio: {
      fr: [
        "Si les activités traditionnelles du notariat constituent le socle de l’activité quotidienne de Sophie Ducamp-Monod, c’est de pouvoir les déployer dans un contexte international qui attise son intérêt.",
        "Elle dispose d’une grande expérience civile et fiscale dans l’acquisition et la vente de biens immobiliers par des non-résidents en France, dans l’anticipation successorale mettant en jeu des intérêts transnationaux, dans le règlement de successions de personnes décédées en France disposant d’un patrimoine hors de France ou à l’inverse de personnes décédées hors de France disposant d’un patrimoine français.",
        "Elle est titulaire du DSN de l’Université de Paris I – Panthéon-Assas en ayant soutenu un mémoire sur la « perception et réception du trust en France » 1997, mais est aussi diplômée de l’Université de Miami (1992) LLM de droit comparé, après avoir suivi une année en auditeur libre à l’Université de la Complutense de Madrid (1991).",
        "Elle est membre actif auprès du Conseil National de l’Union Européenne (organisation des notaires intervenant directement auprès des instances bruxelloises) dans le groupe de réflexion du droit des contrats.",
        "Elle publie ponctuellement des articles juridiques toujours en liaison avec le droit international privé, son domaine de prédilection.",
        "Parlant couramment l’anglais et l’espagnol, elle a développé un réseau de correspondants étrangers lui permettant de traiter les dossiers transfrontaliers.",
        "Le Conseil Supérieur du Notariat a certifié la spécialisation de Me Ducamp-Monod en droit international privé en juin 2017.",
        "Elle est membre actif auprès:",
        "- Du Conseil National de l’Union Européenne (organisation des notaires intervenant directement auprès des instances bruxelloises) dans le groupe de réflexion du droit des contrats",
        "- Et de l’AIJUDEFA association internationale de juristes en droit de la famille, association qui promeut la coopération et les échanges entre  juristes spécialisés en droit de la famille de langue hispanique.",
      ],
      en: [
        "If the traditional notarial matters constitute the base of Sophie Ducamp-Monod’s daily activity, she has been able to deploy them in an international context.",
        "She has extensive legal and tax experience in the acquisition and sale of real estate by non-residents in France, in anticipating inheritance transfers involving transnational interests, in the settlement of estate transfers of persons who died in France with estate outside of France, or on reverse, of persons who died outside of France involving French estate or interests.",
        "She holds a postgraduate diploma in notarial law from the University of Paris I – Panthéon-Assas, having exposed an extended essay on the « Perception and Admission of the Trust in France » 1997, but is also a graduate of the University of Miami (1992) Master of Laws in Comparative Law, after having studied one year as a free auditor at the Universidad de Complutense in Madrid (1991).",
        "She has been certified as specialist of International Private Law by The National Center of the Notarial Profession (2017).",
        "She is an active member of the National Council of the European Union (organization of notaries working directly with the Brussels authorities) in the contract law think-tank.",
        "She occasionally publishes legal articles on international private law, her area of ​​specialization.",
        "Fluent in English and Spanish, she has developed a network of foreign correspondents with English and Spanish speaking countries allowing her to deal with cross-border cases.",
        "Sophie Ducamp-Monod is an active member of",
        "- Sophie Ducamp-Monod is an active member of - The Conseil des Notariats de l'Union Européenne (CNUE). The organisation brings together notaries who intervene directly with the European authorities, to participate in the reflection group on contract law",
        "- The International Association of Family Law Jurists (AIJUDEFA), which promotes cooperation and exchanges between Hispanic family law lawyers.",
      ],
      sp: [
        "Son las actividades notariales tradicionales las que constituyen la base de la actividad diaria de Sophie Ducamp-Monod, su interés es espoleado especialmente cuando puede desplegarlas en un contexto internacional.",
        "Dispone de una amplia experiencia civil y fiscal en la compra y la venta de bienes inmobiliarios por personas no residentes en Francia, en la planificación de la sucesión, donde entran en juego intereses transnacionales, en la resolución de sucesiones de personas fallecidas en Francia que disponían de patrimonio fuera de Francia o, por el contrario, de personas fallecidas fuera de Francia que disponían de patrimonio en Francia.",
        "Es titular del DSN de la Universidad de París I – Panthéon-Assas tras haber presentado una memoria sobre la «Percepción y la Recepción de los trust en Francia» en 1997. Está diplomada en la Universidad de Miami (1992) LLM de derecho comparado, después de haber pasado un año como oyente en la Universidad Complutense de Madrid (1991).",
        "Obtuvo el certificado de especialización en Derecho Internacional Privado otorgado por el Centro Nacional de Formación Notarial en 2017.",
        "Es miembro activo del Consejo Nacional de la Unión Europea (organización de notarios que participa directamente ante las estancias de Bruselas) en el grupo de reflexión sobre el derecho de los contratos.",
        "Publica ocasionalmente artículos jurídicos relacionados con el derecho internacional privado, su ámbito de predilección.",
        "extranjero con ambos países cuyo idioma es inglesa o española.",
        "Es miembro activo:",
        "- del consejo Nacional de la Unión Europea (organización de los notarios interviniendo hacia las instancias bruselenses) en el grupo de reflexión del derecho de los contratos",
        "- y de AIJUDEFA (Asociación Internacional de juristas de Derecho de Familia), asociación que promueve la cooperación y los intercambios entre juristas especializados en derecho de familia de idioma hispánico.",
      ],
    },
  },
  {
    bio: {
      fr: [
        "Associé depuis 2004 après y avoir travaillé en tant que collaborateur, Jean-Sébastien ROUXEL a activement accompagné le développement de l’Office depuis le début des années 2000, notamment auprès des établissements financiers. Il est responsable du service immobilier de l’Étude, s’occupant tant des acquisitions que de la mise en place des financements et des structures d’acquisition.",
        "Jean-Sébastien ROUXEL est spécialisé en droit immobilier et en droit bancaire. Il accompagne les clients tant particuliers, professionnels ou institutionnels dans les dossiers de vente et de financement, du plus simple au plus complexe.",
        "Par ailleurs, passionné par l’Italie, il a développé des échanges avec des correspondants transalpins.",
      ],
      en: [
        "Jean-Sébastien ROUXEL has been a partner since 2004, having worked as an associate before. He has been actively involved in the development of the Office since the early 2000s, notably among financial institutions. He is responsible for the real estate department of the Office, dealing with acquisitions and setting up financing and acquisition structures.",
        "Jean-Sébastien ROUXEL specializes in real estate and banking law. He supports individual, professional and institutional clients, in sales and financing matters, from the simplest to the most complex.",
        "Additionally, passionate about Italy, he has developed relations with trans-Alpine correspondents.",
      ],
      sp: [
        "Socio desde 2004 después de haber trabajado como colaborador, Jean-Sébastien ROUXEL ha seguido de manera activa el desarrollo del despacho desde principios de los años 2000, en particular colaborando con establecimientos financieros. Es responsable del servicio inmobiliario de la notaría, se ocupa tanto de las compras como de la financiación y de las estructuras de adquisición.",
        "Jean-Sébastien ROUXEL está especializado en derecho inmobiliario y en derecho bancario. Asesora a los clientes, ya sean particulares, profesionales o instituciones, en las operaciones de venta y de financiación, desde lo más simple hasta lo más complejo.",
        "Por otra parte, como amante de Italia, ha desarrollado vínculos privilegiados con contactos transalpinos.",
      ],
    },
  },
  {
    bio: {
      fr: [
        "Diplômée de l’École des Hautes Études Commerciales (H.E.C.) dans le cadre de sa formation initiale, Sophie THIBERT-BELAMAN est titulaire du Diplôme Supérieur du Notariat qui lui a été décerné par l’Université de Paris I – Panthéon-Sorbonne.",
        "Manifestant un vif intérêt pour l’interprofessionnalité, Sophie THIBERT-BELAMAN est de surcroît diplômée avocate.",
        "Ayant rejoint l’office notarial pour y être associée en qualité de notaire dès 2004, ses principaux domaines d’activités sont le droit immobilier, l’ingénierie patrimoniale et le droit des sociétés.",
        "Elle réalise des audits immobiliers pour le compte d’investisseurs à l’occasion de la transmission de portefeuilles immobiliers. Elle conseille également des particuliers dans le cadre de transactions immobilières et les aide notamment à structurer et financer leurs projets.",
        "Son parcours atypique s’avère être un précieux atout dans sa mission de conseil auprès de chefs d’entreprises. Sophie THIBERT-BELAMAN intervient auprès d’entrepreneurs en qualité d’experte au sein de l’incubateur HEC et de membre de l’association NCE – Notaires Conseil d’Entreprise.",
        "Après y avoir exercé la fonction de Trésorière de 2020 à 2022, Sophie THIBERT-BELAMAN est à ce jour Première vice-présidente de la Chambre Interdépartementale des Notaires de Paris. Elle est, par ailleurs, membre de l’Institut des Etudes Juridiques section Entreprises-Droit des Affaires du Conseil Supérieur du Notariat, et a participé aux travaux du 118ème Congrès des Notaires de France consacrés à l’ingénieurerie notariale, en qualité de rapporteur au sein de la deuxième commission dédiée au droit de l’entreprise.",
        "Sophie THIBERT-BELAMAN a rejoint un certain nombre de groupements professionnels dont HEC Droit et Entreprise – HEC Gestion de Patrimoine – HEC au féminin – HEC Conseil – HEC Immobilier Distribution Service – HEC Finance – HEC Immobilier et Urbanisme ou encore HEC United Kingdom.",
      ],
      en: [
        "A graduate of the École des Hautes Études Commerciales (H.E.C.) as part of her initial training, Sophie THIBERT-BELAMAN holds the Advanced Diploma in Notarial Law awarded to her by the University of Paris I – Panthéon-Sorbonne.",
        "Demonstrating a keen interest in interprofessionalism, Sophie THIBERT-BELAMAN is also a qualified lawyer.",
        "Having joined the notary office as a partner in her capacity as a notary since 2004, her main areas of activity are real estate law, wealth management, and corporate law.",
        "She conducts real estate audits on behalf of investors during the transfer of real estate portfolios. She also advises individuals on real estate transactions, helping them structure and finance their projects.",
        "Her atypical background proves to be a valuable asset in her advisory role with business leaders. Sophie THIBERT-BELAMAN works with entrepreneurs as an expert within the HEC incubator and as a member of the NCE – Notaires Conseil d’Entreprise association.",
        "After serving as Treasurer from 2020 to 2022, Sophie THIBERT-BELAMAN is currently the First Vice-President of the Interdepartmental Chamber of Notaries of Paris. She is also a member of the Institute of Legal Studies Business Law section of the Superior Council of Notaries, and participated in the work of the 118th Congress of French Notaries dedicated to notarial engineering, as a reporter within the second commission dedicated to business law.",
        "Sophie THIBERT-BELAMAN has joined a number of professional groups including HEC Law and Business – HEC Wealth Management – HEC Women – HEC Consulting – HEC Real Estate Distribution Service – HEC Finance – HEC Real Estate and Urban Planning, and HEC United Kingdom.",
      ],
      sp: [
        "Graduada de la École des Hautes Études Commerciales (H.E.C.) como parte de su formación inicial, Sophie THIBERT-BELAMAN posee el Diploma Superior de Notariado otorgado por la Universidad de París I – Panthéon-Sorbonne.",
        "Demostrando un vivo interés por la interprofesionalidad, Sophie THIBERT-BELAMAN también es abogada titulada.",
        "Habiéndose unido a la oficina notarial como socia en calidad de notaria desde 2004, sus principales áreas de actividad son el derecho inmobiliario, la ingeniería patrimonial y el derecho societario.",
        "Realiza auditorías inmobiliarias en nombre de inversores con motivo de la transmisión de carteras inmobiliarias. También asesora a particulares en transacciones inmobiliarias, ayudándoles a estructurar y financiar sus proyectos.",
        "Su trayectoria atípica resulta ser un valioso activo en su misión de asesoramiento a líderes empresariales. Sophie THIBERT-BELAMAN interviene con empresarios como experta en el incubador HEC y como miembro de la asociación NCE – Notaires Conseil d’Entreprise.",
        "Después de haber ejercido la función de Tesorera de 2020 a 2022, Sophie THIBERT-BELAMAN es actualmente Primera Vicepresidenta de la Cámara Interdepartamental de Notarios de París. Además, es miembro del Instituto de Estudios Jurídicos sección Empresas-Derecho de los Negocios del Consejo Superior del Notariado, y participó en los trabajos del 118º Congreso de Notarios de Francia dedicados a la ingeniería notarial, como ponente en la segunda comisión dedicada al derecho de los negocios.",
        "Sophie THIBERT-BELAMAN se ha unido a varios grupos profesionales, incluidos HEC Derecho y Empresa – HEC Gestión Patrimonial – HEC Mujeres – HEC Consultoría – HEC Servicio de Distribución Inmobiliaria – HEC Finanzas – HEC Inmobiliaria y Urbanismo, y HEC Reino Unido.",
      ],
    },
    labels: [0, 1],
  },
  {
    bio: {
      fr: [
        "Titulaire d’un master II de droit notarial de l’Université PARIS II Panthéon-Assas ainsi que d’un Diplôme Universitaire de Gestion de Patrimoine. Thomas SEMERE était précédemment Notaire associé dans un office notarial « traditionnel », il a rejoint l’étude en 2018 afin de pouvoir y appliquer ses compétences notamment en matière d’anticipation successorale et d’organisation patrimoniale qu’elle soit nationale ou internationale.",
        "Thomas SEMERE ne délaisse toutefois pas les activités plus classiques telles que les ventes dans lesquelles il est soucieux d’accompagner au mieux la clientèle qu’il conseille dans le cadre d’opérations patrimoniales plus complexes.",
        "Ayant vécu à l’étranger et débuté sa carrière dans une étude avec une clientèle internationale importante, il est à même de traiter tous types de dossiers en langue anglaise ainsi qu’avec des interlocuteurs et conseils juridiques étrangers.",
        "En parallèle de cette activité notariale Thomas SEMERE est chargé d’enseignement depuis 2008 pour l’obtention du Diplôme Supérieur du Notariat, il intervient également dans la formation pour l’obtention du Diplôme Universitaire de Droit Patrimonial de la Famille dispensé via l’INAFON.",
        "Ces activités d’enseignements sont complétées par la rédaction et la publication d’articles juridiques dans des revues spécialisées sur des sujets touchant au droit des contrats et au droit patrimonial de la famille.",
      ],
      en: [
        "Thomas Semere holds a master’s degree in notarial law from Université PARIS II Panthéon-Assas and an additional degree in asset management. Previously partner in a « traditional» notary’s office, Thomas Semere joined the firm in 2018 to better apply his skills. He currently specializes in estate planning and patrimonial organization, at both a national and international level.",
        "Thomas Semere also practices more traditional aspects of the notary profession. He advises the sales of real estate and more complex asset transactions. His concern is to always provide the most personalized support and advice to his clients.",
        "His professional activities began abroad in a firm with a large international clientele. Throughout his career overseas, Thomas Semere dealt with a broad range of cases, professionals and clients. He is qualified to handle all types of cases in English. He can interact and collaborate with foreign legal advisers and international contacts if a case so requires.",
        "In parallel with his professional activities, Thomas Semere has for the past decade been responsible as a lecturer in INFN, for the obtention of the Master’s Degree in Notarial Law (Diplôme Supérieur du Notariat). ",
        "He also teaches for INAFON a training course for the University Diploma in Family Property Law (Diplôme Universitaire de Droit Patrimonial de la Famille) since 2008.",
      ],
      sp: [
        "Thomas Semere tiene un máster en derecho notarial por la Université PARIS II Assas y un título adicional en gestión de activos. Anteriormente socio de una notaría, Thomas Semere se incorporó al despacho en 2018 para aplicar mejor sus competencias. Actualmente está especializado en la planificación patrimonial y la organización del patrimonio, tanto a nivel a como internacional.",
        "Thomas Semere también practica aspectos más tradicionales de la profesión de notario. Él asesora las ventas de bienes inmuebles y las transacciones patrimoniales más complejas. Su preocupación es siempre el apoyo y el asesoramiento más personalizados a sus clientes.",
        "Su actividad profesional se inició en el extranjero en un bufete con una amplia clientela internacional. A lo largo de su carrera en el extranjero, Thomas Semere se ocupó de una amplia gama de casos profesionales y clientes. Está capacitado para llevar todo tipo de casos en inglés. Puede puede interactuar y colaborar con asesores jurídicos extranjeros y contactos internacionales si un caso caso lo requiera.",
        "Paralelamente a su actividad profesional, Thomas Semere ha sido durante la última década responsable, como profesor en el INFN, de la obtención del Máster en Derecho Notarial (Diplôme Supérieur du Notariat).",
        "También imparte para el INAFON un curso de formación para el Diploma Universitario de Derecho Patrimonial de la Familia (Diplôme Universitaire de Droit Patrimonial de la Famille) desde 2008",
      ],
    },
  },
  {
    bio: {
      fr: [
        "Titulaire d’un master de droit notarial et d’un diplôme de droit comparé de l’Université de Paris II Panthéon-Assas, Hélène PEISSE a travaillé à Londres, Paris puis San Francisco avant de revenir en 2016 en qualité de notaire salariée à l’Office où elle avait commencé sa carrière.",
        "Au-delà de la pratique du droit notarial classique à laquelle elle est très attachée, Hélène PEISSE est spécialisée dans les dossiers ayant une dimension internationale et notamment les transmissions de patrimoine (donation, succession, testament), les divorces et les acquisitions en France par les non-résidents. Son expérience dans des cabinets d’avocats anglo-saxons lui a permis d’acquérir une grande maîtrise du travail en langue anglaise, une ouverture vers une clientèle internationale et des relations professionnelles précieuses pour le traitement des dossiers transfrontaliers complexes.",
        "Hélène PEISSE publie des articles dans ces domaines et intervient régulièrement dans des conférences en France et à l’étranger. Elle a notamment écrit un livre sur « Les Trusts Américains et la Pratique Notariale Française » paru aux Editions Defrénois.",
      ],
      en: [
        "Helene PEISSE Holds a Master’s degree in notarial law and a degree in International Comparative Law from the University of Paris II Panthéon-Assas. She worked in London, Paris and San Francisco before returning in 2016, now employed as a notary in the Law Firm where she had started her career as associate.",
        "Beyond the practice of classical notarial law to which she is very much attached, Hélène PEISSE specializes in cases with an international emphasis, particularly in estate planning (gifts, partition and bequeath, wills), divorces and acquisitions in France by non-residents. Her experience in British and American Law Firms has enabled her to acquire a great working command of the English language, an openness towards international clients and professional relations valuable for dealing with complex cross-border cases.",
        "Hélène PEISSE publishes articles in these fields and regularly speaks at conferences in France and abroad. Notably, she wrote a book on « American Trusts and French Notarial Practice » published by Editions Defrénois (2013).",
      ],
      sp: [
        "Titular de un máster en derecho notarial y de un diploma de derecho comparado de la Universidad de París II Panthéon-Assas, Hélène PEISSE ha trabajado en Londres, París y San Francisco antes de regresar en 2016 en calidad de notaria asalariada al despacho donde empezó su carrera.",
        "Además de la práctica del derecho notarial clásico, con la que está muy vinculada, Hélène PEISSE está especializada en operaciones que tienen una dimensión internacional y en particular con las transmisiones de patrimonio (donación, sucesión, testamento), los divorcios y las compras en Francia por personas no residentes. Su experiencia en bufetes de abogados anglosajones le ha permitido adquirir un gran dominio del trabajo en lengua inglesa, una apertura hacia una clientela internacional y relaciones profesionales valiosas para la tramitación de las operaciones transfronterizas complejas.",
        "Hélène PEISSE publica artículos en estos ámbitos e interviene con regularidad en conferencias en Francia y en el extranjero. En particular ha escrito un libro: «Les Trusts Américains et la Pratique Notariale Française» (Los trusts estadounidenses y la práctica notarial francesa), publicado en Editions Defrénois.",
      ],
    },
    labels: [2],
  },
  {
    bio: {
      fr: [
        "Notaire salarié de l’Etude depuis 2019, Sylvain Champeix est titulaire du Diplôme de Premier Clerc de Notaire qui lui a permis d’accéder, du fait de sa solide expérience professionnelle acquise depuis de nombreuses années au sein de plusieurs études parisiennes en qualité de clerc habilité, aux fonctions de notaire.",
        "Il intervient essentiellement dans tous types d’opérations immobilières et commerciales, simples ou complexes, pour le compte de clients particuliers ou professionnels, et plus spécialement dans le domaine de la cession d’actifs immobiliers d’entreprise. ",
      ],
      en: [
        "Sylvain Champeix holds a Notary Clerk's Diploma (Diplôme de Premier Clerc de Notaire). Over many years, he acquired solid professional experiences in several Parisian law firms as an authorized clerk, before becoming a notary.",
        "Sylvain’s professional expertise lies principally in all types of real estate and commercial transactions, being particularly competent in sales of commercial real estate assets. He offers guidance and support to both private and professional clients, either on simple or complex cases.",
      ],
      sp: [
        "Notario asalariado de la notaría desde 2019, Sylvain Champeix es titular del Diploma de Primer Secretario Notarial, que le ha permitido convertirse en notario gracias a la sólida experiencia profesional que ha adquirido durante muchos años en varias notarías parisinas como secretario cualificado.",
        "Interviene principalmente en todo tipo de transacciones inmobiliarias y comerciales, simples o complejas, por cuenta de clientes privados o profesionales, y más concretamente en el ámbito de la transmisión de activos inmobiliarios de empresas.",
      ],
    },
  },
  {
    bio: {
      fr: [
        "Titulaire d’un Diplôme supérieur du Notariat et d’un Diplôme universitaire de Gestion de patrimoine, Héloïse DO est entrée dans la profession en septembre 1996. Elle a développé son expérience dans différentes études en Province puis à Paris, en traitant des dossiers très divers tant en droit de la famille qu’en droit immobilier, en droit commercial et en droit bancaire.",
        "Héloïse DO a rejoint l’office en janvier 2019 en tant que Notaire salarié pour intégrer le pôle immobilier.",
        "Elle accompagne les particuliers dans leurs projets immobiliers mais également les professionnels, ayant développé une expertise particulière en droit commercial et notamment un grand intérêt pour les cessions de fonds de commerce et de baux commerciaux.",
        "Parlant couramment vietnamien, elle conseille également les clients asiatiques.",
      ],
      en: [
        "Héloïse Do holds a Master’s Degree in Notarial Law (Diplôme supérieur du Notariat) as well as a Master degree in asset management. She has practiced in the profession since 1996.",
        "Héloïse DO joined Ducamp-Monod & Partner in January 2019 as a notary to integrate the Real Estate Department.",
        "She assists and advises individuals and professionals in their real estate projects, having developed expertise in commercial law. She is highly qualified in matters of business transfers and commercial leases.",
        "She gained unique experience throughout her career in various notary firms, throughout France and most recently in Paris. She demonstrated her skills and talents with a wide range of cases in family law, real estate law, commercial law and banking law.",
        "Additionally, Héloïse DO is fluent in Vietnamese. She advises many Asian clients.",
      ],
      sp: [
        "Titular del Diploma Superior de Notaría y de un título universitario de gestión del patrimonio, Héloïse DO entró en la profesión en septiembre de 1996. Ha desarrollado su experiencia en varias notarías en provincias y luego en París, ocupándose de varios casos en derecho de familia, derecho inmobiliario, derecho comercial y derecho bancario.",
        "Héloïse DO se incorporó al despacho en enero de 2019 como notario asalariado para integrar el departamento inmobiliario.",
        "Asiste a los particulares en sus proyectos inmobiliarios pero también a los profesionales. Ha desarrollado una experiencia particular en derecho mercantil y un gran interés en las transmisiones de empresas y los arrendamientos de locales comerciales.",
        "Domina el vietnamita y aconseja a los clientes asiáticos.",
      ],
    },
  },
  {
    bio: {
      fr: [
        "Titulaire d’une maîtrise de droit privé de l’Université de PARIS X Nanterre, Sandrine Deswel-Fernandes a débuté sa carrière en 1990, dans cette même étude, et avec son prédécesseur Maître Michel Ducamp.",
        "Elle s’est, dans un premier temps, formée au droit immobilier, puis au droit de la famille, pour ensuite se spécialiser essentiellement en droit de la famille et principalement pour le traitement des dossiers de successions.",
        "Après 30 années de pratique en qualité de clerc, elle vient d’être nommée Notaire.",
        "Elle a fait des successions « sa spécialité », pour pouvoir accompagner les familles au mieux, être à leur écoute, et répondre à leurs besoins tant sur le plan juridique, et fiscal que sur le plan humain.",
      ],
      en: [
        "Sandrine Deswel-Fernandes holds a Master's degree in private law from the University of PARIS X Nanterre. She started her career in the Ducamp-Monod & Partners firm in 1990. After 30 years of practice as a clerk, she qualified as a notary, in Ducamp-Monod & Partners.",
        "She first trained in real estate law before specializing in family law, processing inheritance files. Due to her expertise concerning inheritance matters, she is able to provide the best support and advice to families whom she represents. The personalized guidance she offers, strengthened by her listening skills, allows her to advise efficiently her clients on a legal and fiscal level.",
      ],
      sp: [
        "Licenciada en derecho privado por la Universidad de París X Nanterre, Sandrine Deswel-Fernandes comenzó su carrera en 1990, en la misma notaría, con su predecesor Michel Ducamp.",
        "Primero se formó en derecho inmobiliario, luego en derecho de familia, y más tarde se especializó principalmente en derecho de familia y sobre todo en herencias.",
        "Después de 30 años de ejercicio como administrativa, acaba de ser nombrada notaria.",
        "Ha hecho de la herencia su especialidad, para poder acompañar a las familias de la mejor manera posible, escucharlas y satisfacer sus necesidades a nivel jurídico, fiscal y humano.",
      ],
    },
  },
  {
    bio: {
      fr: [
        "Arrivée à l’Office « Ducamp-Monod et Associés » en 2018, Gladys HACHON exerce principalement ses fonctions au sein du Service actes courants. Elle conseille et accompagne les clients de l’Etude pour toutes les questions relatives aux ventes et acquisitions immobilières, tant dans l’ancien que dans le neuf (VEFA, défiscalisation immobilière,…). Sa compétence s’étend aux immeubles complexes et cessions d’immeubles d’activités (locaux commerciaux, industriel, bureaux,…).",
        "Elle est par ailleurs en mesure d’assurer le suivi des dossiers de clients étrangers, grâce à ces compétences en langue anglaise. Elle travaille ainsi régulièrement avec les associés en charge du Droit international, sur leurs dossiers immobiliers.",
        "Elle apprécie les challenges et la prise en charge de dossiers très divers. ",
        "Ayant effectué un parcours atypique, en commençant par le BTS Notariat pour obtenir, à l’issue de ses études, le Diplôme Supérieur de Notariat à l’Université Paris 12, sous la Direction de Mme MAZEAUD-LEVENEUR, elle garde à cœur d’intervenir auprès des plus jeunes. Elle a par exemple été à plusieurs reprises membre de jury des épreuves orales de BTS Notariat, qui consistent à évaluer les activités faites en stage par les étudiants.",
      ],
      en: [
        "Gladys HACHON holds a Master’s degree in Notarial Law (Diplôme Supérieur de Notariat) from the Université Paris 12, under the direction of Mrs. MAZEAUD-LEVENEUR. She joined Ducamp-Monod & Partners in 2018 and mainly works in the Real Property Transfers department",
        "Gladys enjoys challenges: as such, she handles a wide range of cases. She advises and assists the law firm's clients in all matters relating to real estate sales and acquisitions, both in old and new buildings (VEFA), real estate tax exemptions. Her expertise extends to complex properties and the transfers of business, commercial, industrial and office premises.",
        "Within our firm and the profession, she is particularly committed to young people: she has been on several occasions a member of the jury for the oral exams of the BTS Notariat, which consists in evaluating the activities carried out by the students during their internship.",
        "Fluent in English, she accompanies foreign clients in their projects. She also regularly works on real estate cases with our partners in charge of international law matters.",
      ],
      sp: [
        "Llegada en la Notaría “DUCAMP-MONOD & Socios” en 2018, Gladys HACHON ejerce principalmente sus funciones dentro del servicio inmobiliario. Aconseja y acompaña a los clientes para todas preguntas relativas a las ventas y/o las compras inmobiliarias, tan ancianas como nuevas, incluso con las consecuencias tributarias de ambas. ",
        "Su competencia se extiende hacia asuntos complejos y cesiones de inmuebles profesionales (oficinas, local destinado a comercio o/y a industria).",
        "Por otra parte, esta también capaz poner sus facultades al servicio de la clientela extranjera, gracia a su capacidad exprimirse en inglés. ",
        "Trabaja también regularmente con los notarios asociados en la Notaría en carga de las compraventas inmobiliarias internacionales (inmuebles ubicados en Francia con residentes fuera de Francia). ",
        "Aprecia los desafíos y la riqueza surgida de la diversidad en los asuntos que se les confía.",
        "Habiendo efectuado un recorrido atípico, empezando sus estudios por un diploma de técnico superior en el notariado, para finalmente ser diplomada del Diploma Superior del Notariado en la Universidad de Paris 12, bajo la dirección de la Profesora Doña MAZEAUD-LEVENEUR, es importante para ella intervenir hacia los jóvenes profesionales. Por ejemplo, fue en varias ocasiones miembro del jurado para las pruebas orales del diploma de técnico superior en el notariado, que consiste en evaluar las actividades realizadas en la práctica hechas por les estudiantes.",
      ],
    },
  },
  {
    bio: {
      fr: [
        "Titulaire d’un Diplôme supérieur du Notariat de l’Université PARIS II Panthéon-Assas, Audrey ALCABAS est entrée dans la profession en février 2008. Elle a développé son expérience dans différentes études à Paris et proche banlieue, en traitant des dossiers principalement en droit de la famille et en gestion patrimoniale.",
        "Audrey ALCABAS a rejoint l’office en juin 2021 en tant que Notaire assistant puis depuis juin 2022 en tant que Notaire salarié pour intégrer le pôle famille.",
        "Elle est spécialisée en droit de la famille et en gestion patrimoniale. Elle accompagne les familles dans les différentes étapes de leur vie, notamment dans le traitement des dossiers de successions, transmissions anticipées du patrimoine, régime matrimoniaux, pactes civils de solidarité. Elle sait être à leur écoute, et répondre à leurs besoins tant sur le plan juridique, et fiscal que sur le plan humain.",
      ],
      en: [
        "Audrey ALCABAS holds a Master’s degree in Notarial Law (Diplôme Supérieur de Notariat) from the Université Paris II Panthéon-Assas. She practises in the profession since February 2008. She developed her experience in various Notarial firms in Paris and Greater Paris, dealing mainly with family law and asset management cases, which enabled her to specialise in both  fields.",
        "She joined the office in June 2021 as an assistant notary. Since June 2022, she has been appointed notary, after having integrated our family division.",
        "Audrey is particularly attentive to the needs of families at different stages of their lives and responds to their legal and tax needs with benevolence and conscientiousness. She handles cases as diverse as estate planning, antenuptial agreements, civil partnerships, or transfers by inheritance.",
      ],
      sp: [
        "Titular del Diploma Superior del Notariado de la Universidad de PARIS II Pantheon-Assas, Audrey ALCABAS empezó en la profesión en febrero de 2008",
        "Desarrolla su experiencia en varias notarías en Paris o sus afueras, solucionando asuntos principalmente en derecho de la familia y en organización patrimonial.",
        "Audrey ALCABAS integro la Notaría en junio de 2021 como notario auxiliar y luego como notario asalariado en el departamento de derecho de la familia.",
        "Es especializada en derecho de la familia y gestión patrimonial. Acompaña las familias en las distintas etapas de su vida, para todos los asuntos que tratan de sucesiones, transmisiones anticipadas, cambio de regímenes matrimoniales o uniones registradas. Sabe estar atenta, contestar en términos humanos a las problemáticas jurídicas que le están sometidas.",
      ],
    },
  },
  {
    bio: {
      fr: [
        "Notaire salariée au sein de l’étude DUCAMP-MONOD & Associés.",
        "Brigitte GIUDICE-NIZARD exerce dans le notariat depuis 2005, forte d’une expérience professionnelle en province et à PARIS.",
        "Diplômée des facultés de NICE et d’AIX-MARSEILLE, titulaire du Diplôme Supérieur de Notariat, elle met désormais tout particulièrement son expérience et ses connaissances au service des particuliers qu’elle accompagne dans leurs projets personnels et professionnels.",
        "Elle conseille ses clients en langues française et anglaise.",
      ],
      en: [
        "Brigitte GIUDICE-NIZARD holds a degree from the faculties of Nice and Aix-Marseille, as well as a Master’s Degree in Notarial Law (Diplôme Supérieur de Notariat).",
        "Since 2005, Brigitte has worked as a notary in Nice, Vence and Paris. She joined Ducamp-Monod & Partners in 2021, where she used her experience and knowledge to assist private individuals in their professional and personal projects.",
        "She advises her clients in both French and English.",
      ],
      sp: [
        "Notario asalariada dentro de la Notaría DUCAMP-MONOD & socios, Brigitte GIUDICE-NIZARD practica en el notariado desde 2005, teniendo une experiencia tan en la provincia como en Paris.",
        "Diplomada de las facultades de NIZA y AIX-MARSELLA, titular del Diploma Superior del Notariado, invierte ahora principalmente su experiencia y sus conocimientos al servicio de la clientela particular; La acompaña en sus proyectos personales o profesionales.",
        "Aconseja sus clientes en francés o inglés.",
      ],
    },
  },
  {
    bio: {
      fr: [
        "Entré dans la profession depuis 2003, titulaire du Diplôme Supérieur du Notariat depuis 2011, ayant eu une expérience dans tous les domaines traités par le notariat, et ayant complété son expertise juridique pendant trois ans dans un cabinet d’avocat, Jean-Rémi CABANE a rejoint l’Etude en 2019 et est notaire salarié depuis 2022.",
        "Il est spécialisé en immobilier des particuliers et accompagne ses clients dans leurs projets personnels et professionnels.",
      ],
      en: [
        "Jean-Rémi CABANE is a notary since 2003. He holds a Master’s degree in Notarial Law (Diplôme Supérieur de Notariat) since 2011 and has since multiplied his experiences in all notarial domains.",
        "After a three-year experience in a Law firm, which strengthened his legal expertise, Jean-Rémi joined Ducamp-Monod & Partners in 2019, and was appointed notary since 2022.",
        "Jean-Rémi CABANE specialises in real estate for individuals and professionals. He assists and offers personal guidance to his clients for their projects.",
      ],
      sp: [
        "Siendo en la profesión desde 2003, titular del Diploma Superior del Notariado desde 2011, teniendo una experiencia en todos los asuntos notariales tradicionales, y habiendo completado su pericia jurídica durante tres años en un gabinete de abogados, Jean-Rémi CABANE se incorporó en la Notaría desde 2019, y fue nombrado notario asalariado en 2022.",
        "Se especializa en el derecho inmobiliario de los particulares, y acompaña sus clientes en sus proyectos tan personales como profesionales.",
      ],
    },
  },
  {
    bio: {
      fr: [
        "Titulaire d’un Diplôme Supérieur du Notariat, Magali DELAUNAY-BRUNEL est une jeune notaire avec une expérience polyvalente acquise dans différentes Etudes, d’abord en Province puis à PARIS.",
        "Par souci   d’accompagner les familles dans des moments clés de leurs vies, elle s’est spécialisée en droit de la famille.",
        "Soucieuse de trouver des solutions amiables, elle est en charge de divorces, successions, donations, toujours avec rigueur et humanité.",
      ],
      en: [
        "Magali DELAUNAY-BRUNEL holds a Master’s degree in Notarial Law (Diplôme Supérieur de Notariat).",
        "As a young notary, she has acquired a wide range of experience in various Notarial  Offices, first in the provinces and then in Paris.",
        "Magali specializes in family law. As such, she is committed to supporting and assisting families at key moments in their lives.",
        "She assists her clients in finding amicable solutions, whether for divorces, inheritances or gifts, and always with rigour and humanity.",
      ],
      sp: [
        "Titular del Diploma Superior del notariado, Magali DELAUNAY-BRUNEL es una joven notaria con una experiencia polivalente adquirida en varias notarías, al principio en las provincias, y luego en Paris.",
        "Por razones de acompañar a las familias en los momentos llaves de sus vidas, se especializa en derecho de la familia.",
        "Preocupada por la búsqueda de soluciones amistosas, arregla los divorcios, sigue y resuelve las sucesiones, prepara donaciones, siempre con rigor y humanidad.",
      ],
    },
  },
];

// ARTICLES ------------------------------------------------------------------------------------------------------------------------------------------------------------
const articles = [
  {
    author: 0,
    src: "https://drive.proton.me/urls/QE3VFNCGYR#P6IRR21ktT7b",
    date: "2019",
    by: "International Family Law Journal, issue 3 p196",
    fr: "Matrimonial property regime and property consequences of registered partnerships: two new European regulations comming into effect",
    en: "Matrimonial property regime and property consequences of registered partnerships: two new European regulations comming into effect",
    sp: "Matrimonial property regime and property consequences of registered partnerships: two new European regulations comming into effect",
  },
  {
    author: 0,
    date: "2003",
    by: "Livre Bleu du Notariat Latin Ed Bruylant",
    fr: "Régimes matrimoniaux – successions et libéralités dans les relations internationales et internes",
    en: "Matrimonial property regimes - succession and gifts in international and domestic relations",
    sp: "Regímenes matrimoniales: sucesiones y donaciones en las relaciones internacionales y nacionales",
  },
  {
    author: 0,
    date: "2009",
    by: "Gaz Pal, p9",
    fr: "Vente et acquisition de biens immobiliers par des ressortissants du Royaume-Uni",
    en: "Sale and acquisition of property by UK nationals",
    sp: "Venta y adquisición de propiedades por parte de nacionales del Reino Unido",
  },
  {
    author: 0,
    date: "2009",
    by: "Gaz Pal 2-3, p 3",
    fr: "Couples présentant un élément d’extranéité : ou comment désamorcer une bombe à retardement",
    en: "Couples with a foreign element: or how to defuse a time bomb",
    sp: "Parejas con un elemento extraño: o cómo desactivar una bomba de relojería",
  },
  {
    author: 0,
    src: "https://drive.proton.me/urls/9AAZTSA7CM#kTHZF2swRYis",
    date: "2015",
    by: "Droit de la famille Dalloz n°5",
    fr: "De quelques difficultés prévisibles liées à l’application du règlement européen sur les successions",
    en: "Some foreseeable difficulties linked to the application of the European regulation on successions [fr]",
    sp: "Algunas dificultades previsibles en la aplicación del Reglamento de Sucesión de la UE [fr]",
  },
  {
    author: 0,
    src: "https://drive.proton.me/urls/6MF7SQBQWC#frQq1hUaboQk",
    date: "2018",
    by: "Family Law Journal",
    fr: "Make a choice : Savoir changer de régime matrimonial avant janvier 2019",
    en: "Make a choice: How to change your matrimonial property regime before January 2019 [fr]",
    sp: "Elige: cómo cambiar el régimen económico matrimonial antes de enero de 2019 [fr]",
  },
  {
    author: 0,
    src: "https://drive.proton.me/urls/2X3BF6QEZG#60tYDkPbEQy1",
    date: "2021",
    by: "RIP, mars, n° 07, p.41/48",
    fr: "La réception en France d'un trust valablement constitué en pays de common law ou: comment faire entrer un cercle dans un carré?",
    en: "The reception in France of the validly constituted trust in Common Law countries or how to fit a circle into a square? [fr]",
    sp: "La recepción en Francia del “trust” válidamente constituido en los países del Common Law o ¿ cómo encajar un círculo en un cuadrado? [fr]",
  },
  {
    author: 0,
    src: "https://drive.proton.me/urls/W6FCCX2JWC#J7fiexK7PUaO",
    date: "2023",
    by: "International Heritage Forum",
    fr: "Rapport pour la France pour le forum international de l'héritage",
    en: "Report for France for the international heritage forum [en]",
    sp: "Informe sobre Francia para el Foro Internacional del Patrimonio [fr]",
  },
  {
    author: 3,
    date: "2010",
    by: "AJ famille",
    fr: "Article « L’administration de l’indivision»",
    en: "Article 'The administration of undivided ownership'",
    sp: "Artículo 'La administración de la propiedad indivisa'",
  },
  {
    author: 3,
    date: "2011",
    by: "La Correspondance Economique du 23 juin 2011 page 35",
    fr: "Intervention relative à la loi de Finances rectificative pour 2011",
    en: "Intervention on the Amending Finance Act for 2011",
    sp: "Intervención sobre la ley de finanzas rectificativa para 2011",
  },
  {
    author: 3,
    src: "https://drive.proton.me/urls/QXPM6JTBSG#DazLA7aT0XPU",
    date: "2013",
    by: "Chronique bi-annuelle « actes courants et techniques contractuelles »",
    fr: "Commentaires d’arrêts au JCP N sous la direction de Monsieur le Professeur Mustapha Mekki de 2013 à 2016",
    en: "Commentary on judgments in the JCP N under the direction of Professor Mustapha Mekki from 2013 to 2016 [fr]",
    sp: "Comentarios sobre las sentencias en el PNC N bajo la dirección del profesor Mustapha Mekki de 2013 a 2016 [fr]",
  },
  {
    author: 3,
    src: "https://drive.proton.me/urls/X4KDR206K4#zFsovrlkYRMt",
    date: "2014",
    by: "Defrénois n°7, page 366.",
    fr: "« L’incorporation des donations antérieures » en collaboration avec Madame le Professeur Sophie Gaudemet",
    en: "The incorporation of prior donations in collaboration with Professor Sophie Gaudemet [fr]",
    sp: "La incorporación de donaciones anteriores en colaboración con la profesora Sophie Gaudemet [fr]",
  },
  {
    author: 3,
    src: "https://drive.proton.me/urls/15BSAW1BEW#kmeOwmniBVbL",
    date: "2015",
    by: "Vente d’immeubles : droits de préférence et droits de préemption, JCP N du 13 mars, page 1093.",
    fr: "Article écrit en collaboration avec le Professeur Laurent Leveneur",
    en: "Article written in collaboration with Professor Laurent Leveneur [fr]",
    sp: "Artículo escrito en colaboración con el profesor Laurent Leveneur [fr]",
  },
  {
    author: 3,
    src: "https://drive.proton.me/urls/VTQQ4HQCY8#oEevyGiV0Ymv",
    date: "2015",
    by: "Un peu de consolidation, beaucoup d’innovations, JCP N du 20 novembre, page 1208.",
    fr: "Article écrit en collaboration avec le Professeur Mustapha Mekki",
    en: "Article written in collaboration with Professor Mustapha Mekki [fr]",
    sp: "Artículo escrito en colaboración con el profesor Mustapha Mekki [fr]",
  },
  {
    author: 4,
    date: "2015",
    by: "Defrénois, 15 décembre, n° 23, p.1243",
    fr: "Organisation du patrimoine au travers d’un trust américain",
    en: "Organisation of assets through an American trust [fr]",
    sp: "Organización de los activos a través de un fideicomiso americano [fr]",
  },
  {
    author: 4,
    src: "https://drive.proton.me/urls/ZV879VD648#BTVJux8dLsAc",
    date: "2009",
    by: "La Gazette du Palais, 9-10 octobre",
    fr: "Opérations immobilières et éléments d’extranéité : les enjeux de la localisation internationale d’une personne",
    en: "Real estate transactions and foreign elements: the challenges of the international location of a person [fr]",
    sp: "Transacciones inmobiliarias y elementos extranjeros: los retos de la localización internacional de una persona [fr]",
  },
  {
    author: 4,
    src: "https://drive.proton.me/urls/YPWF1S9K5R#EwknSDc9jSwi",
    date: "2016",
    by: "Droit et Patrimoine, cahier spécial sur « le mariage international », sept, n°261, p61 ",
    fr: "Préoccupations patrimoniales lors de l’expatriation d’un couple",
    en: "Wealth concerns when a couple expatriates [fr]",
    sp: "Preocupación por el patrimonio cuando una pareja se expatría [fr]",
  },
  {
    author: 4,
    src: "https://drive.proton.me/urls/KKEGJZCY0M#9ylX2XWcq9f2",
    date: "2017",
    by: "Droit et Patrimoine, cahier spécial sur « le divorce international », avril, n°268, p46)",
    fr: "L’aspect patrimonial du divorce international",
    en: "The property aspect of international divorce [fr]",
    sp: "El aspecto patrimonial del divorcio internacional [fr]",
  },
  {
    author: 4,
    src: "https://drive.proton.me/urls/4MVY3YCJR8#7iNcJbA7Ykvk",
    date: "2017",
    by: "Droit et Patrimoine, nov, n°274 p14",
    fr: "Conseils pratiques avant d’établir une professio juris au profit de la loi anglaise",
    en: "Practical advice before establishing a professio juris in favour of English law [fr]",
    sp: "Consejos prácticos antes de establecer una professio juris a favor del derecho inglés [fr]",
  },
  {
    author: 4,
    src: "https://drive.proton.me/urls/XMPYHV5QF8#lbfwj2lsarDW",
    date: "2019",
    by: "Defrénois, 3 oct, n° 40, p.17",
    fr: "Les couples franco anglais face au divorce",
    en: "Franco-British couples facing divorce [fr]",
    sp: "Las parejas franco-británicas se enfrentan al divorcio [fr]",
  },
  {
    author: 4,
    src: "https://drive.proton.me/urls/RPM7V5F4F0#nfGf9ziPoTaN",
    date: "2019",
    by: "Defrénois, 21 nov, n° 47, p.15",
    fr: "Les couples franco anglais face au décès",
    en: "Franco-British couples facing death [fr]",
    sp: "Las parejas franco-británicas se enfrentan a la muerte [fr]",
  },
  {
    author: 4,
    src: "https://drive.proton.me/urls/CJ75FFVVR0#Wd2wYvRQzLLE",
    date: "2021",
    by: "RIP, déc, n° 09, p.82/85",
    fr: "Évolution de la réserve héréditaire en droit français une occasion manquée",
    en: "Evolution of the reserved portion of the estate in French law - a missed opportunity [fr]",
    sp: "La evolución de la reserva hereditaria en el derecho francés: una oportunidad perdida [fr]",
  },
  {
    author: 4,
    src: "https://drive.proton.me/urls/JTNSEQDXY0#zflh7e0VfYMt",
    date: "2022",
    by: "Actes pratiques & stratégies patrimoniale, juillet, n° 03, p.57/63",
    fr: "Publicité foncière du trust",
    en: "Land publicity of the trust [fr]",
    sp: "Publicidad del terreno del fideicomiso [fr]",
  },
  {
    author: 4,
    src: "https://drive.proton.me/urls/C4QG9G224C#kd1YtmuJSA03",
    date: "2019",
    by: "Droit et patrimoine, mars, n° 289, p.25/28",
    fr: "Quelle est l'utilisation classique d'un trust-anglo-saxon ?",
    en: "What is the classic use of an Anglo-Saxon trust? [fr]",
    sp: "¿Cuál es el uso clásico de un trust anglosajón? [fr]",
  },
];

const videos = [
  {
    author: 0,
    src: "https://www.youtube.com/embed/vu4f2ElKkVs",
    tags: ["int"],
    id: " expatriation",
    fr: "Expatriation : l'essentiel à connaître avant de sauter le pas",
    en: "Expatriation: what you need to know before taking the plunge [fr]",
    sp: "Expatriación: lo que hay que saber antes de dar el paso [fr]",
  },
  {
    author: 2,
    src: "https://www.youtube.com/embed/3F2PPBYvHAs",
    tags: ["firm", "asset", "stt"],
    id: " SCI",
    fr: "La SCI pour acquérir un bien immobilier ? Quel intérêt ?",
    en: "The SCI to acquire a property? What's the point? [fr]",
    sp: "¿El SCI para adquirir una propiedad? ¿Qué sentido tiene? [fr]",
  },
  {
    author: 3,
    src: "https://www.youtube.com/embed/eJ_VxI5Hfw0",
    tags: ["asset"],
    id: " dutreuil",
    fr: "Optimiser la transmission de votre entreprise avec le pacte Dutreil",
    en: "Optimise the transfer of your company with the Dutreil pact [fr]",
    sp: "Optimice el traspaso de su empresa con el pacto Dutreil [fr]",
  },
  {
    author: 4,
    src: "https://www.youtube.com/embed/sSRk9A8XKEA",
    tags: ["asset", "fml"],
    id: " donationPartage",
    fr: "Transmettre son patrimoine : la donation-partage",
    en: "Passing on one's assets: the shared donation [fr]",
    sp: "Transmisión del patrimonio: donaciones compartidas [fr]",
  },
  {
    author: 4,
    src: "https://www.youtube.com/embed/T3G3OfYkJjs",
    tags: ["int", "stt"],
    id: "acquisitionNonRes",
    fr: "Acquisition for Non Residents [en] ?",
    en: "Acquisition for Non Residents",
    sp: "Acquisition for Non Residents [en]",
  },
  {
    author: 0,
    src: "https://www.youtube.com/embed/SYHqzYE5RgQ",
    tags: ["asset"],
    id: "cedant",
    fr: "Préocupations majeures du cédant d'entreprise",
    en: "Major concerns of the business transferor [fr]",
    sp: "Principales preocupaciones del transmitente de la empresa [fr]",
  },
  {
    author: 0,
    src: "https://www.youtube.com/embed/io1d3uwXk3c",
    tags: ["asset"],
    id: "orga_patrimoniale",
    fr: "Le cédant doit repenser son organisation patrimoniale",
    en: "Expatriation: what you need to know before taking the plunge [fr]",
    sp: "Expatriación: lo que hay que saber antes de dar el paso [fr]",
  },
  {
    author: 0,
    src: "https://www.youtube.com/embed/tTP51L4e4qM",
    tags: ["asset"],
    id: "repreneur",
    fr: "Outils patrimoniaux à la disposition du repreneur",
    en: "The transferor must rethink his asset organisation [fr]",
    sp: "El transmitente debe replantearse su organización patrimonial [fr]",
  },
  {
    author: 0,
    src: "https://www.youtube.com/embed/4DyHrjxGhuY",
    tags: ["asset"],
    id: "regime",
    fr: "Régime matrimonial du repreneur",
    en: "Matrimonial regime of the buyer [fr]",
    sp: "Régimen matrimonial del comprador [fr]",
  },
];

const labels = [
  {
    author: 0,
    src: require("@/assets/UIA.webp"),
    fr: "UIA - International\nAssociation of Lawyers",
    en: "UIA - International\nAssociation of Lawyers",
    sp: "UIA - International\nAssociation of Lawyers",
    text_fr:
      "L'étude sait répondre à tout types de besoins pour les étrangers & expatriés investissant en France",
    text_en:
      "With their experience abroad, notaries are able to meet all types of needs for foreigners & expatriates",
    text_sp:
      "Con su experiencia en el extranjero, los notarios pueden satisfacer todo tipo de necesidades para extranjeros y expatriados.",
  },
  {
    author: 0,
    src: require("@/assets/AIJUDEFA.webp"),
    fr: "Asociación Internacional\nde Juristas de Derecho\nde Familia",
    en: "Asociación Internacional\nde Juristas de Derecho\nde Familia",
    sp: "Asociación Internacional\nde Juristas de Derecho\nde Familia",
  },
  {
    author: 2,
    src: require("@/assets/label-cmp.webp"),
    fr: "Notaires Conseils\n d'Entreprises",
    en: "Notary Company\n Councils",
    sp: "Notarios Consejos\n de empresa",
    text_fr:
      "Notre office offre son expertise dans l'accompagnement de projet entrepreneurial",
    text_en:
      "Our office offers its expertise in supporting entrepreneurial projects",
    text_sp:
      "Nuestra oficina ofrece su experiencia en el apoyo a proyectos emprendedores",
  },
  {
    author: 4,
    src: require("@/assets/label-inter.webp"),
    fr: "Notaires Conseil\n International",
    en: "Notary International\n Consulting",
    sp: "Notario en la asesoría\n Internacional",
    text_fr:
      "L'étude sait répondre à tout types de besoins pour les étrangers & expatriés investissant en France",
    text_en:
      "With their experience abroad, notaries are able to meet all types of needs for foreigners & expatriates",
    text_sp:
      "Con su experiencia en el extranjero, los notarios pueden satisfacer todo tipo de necesidades para extranjeros y expatriados.",
  },
  {
    author: 2,
    src: require("@/assets/label-env.webp"),
    fr: "Notaires Conseils\n en Aménagment et Environement",
    en: "Notaries Advising\n on Planning and Environment",
    sp: "Notarios Consejos\n  Medio Ambiente",
    text_fr:
      "L'étude se fait fort d'accompagner ses clients dans la transition durable",
    text_en:
      "The office is committed to supporting its clients in the sustainable transition",
    text_sp:
      "El estudio apuesta por apoyar a sus clientes en la transición sostenible",
  },
];

export { profiles, biographies, videos, articles, labels };
